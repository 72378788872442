import {
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
} from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { EmailValidator, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTable, MatDialog, MatPaginator, MatSort } from "@angular/material";
import { BlockgroupModel } from "../../../../core/blockgroup/blockgroup.model";
import { ActivatedRoute, Router } from "@angular/router";
import {
	LayoutConfigService,
	SubheaderService,
} from "../../../../core/_base/layout";
import {
	LayoutUtilsService,
	MessageType,
	QueryParamsModel,
} from "../../../../core/_base/crud";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
import {
	selectBlockGroupActionLoading,
	selectBlockGroupById,
	selectLastCreatedBlockGroupId,
} from "../../../../core/blockgroup/blockgroup.selector";
import {
	BlockGroupOnServerCreated,
	BlockGroupRequested,
} from "../../../../core/blockgroup/blockgroup.action";
import { SelectionModel } from "@angular/cdk/collections";
import { BlockGroupDataSource } from "../../../../core/blockgroup/blockgroup.datasource";
import { BlockGroupService } from "../../../../core/blockgroup/blockgroup.service";
import { distinctUntilChanged, skip } from "rxjs/operators";
import { EditStructureListDialogComponent } from "../edit-structure-list/edit-structure-list.dialog.component";
import { environment } from "../../../../../../src/environments/environment";

@Component({
	selector: "kt-add-block-group",
	templateUrl: "./add-block-group.component.html",
	styleUrls: ["./add-block-group.component.scss"],
})
export class AddBlockGroupComponent implements OnInit, OnDestroy {
	dataSource: BlockGroupDataSource;
	blockgroup: BlockgroupModel;
	blockgroupId$: Observable<string>;
	oldUser: BlockgroupModel;
	selectedTab = 0;
	loading$: Observable<boolean>;
	blockGroupForm: FormGroup;
	hasFormErrors = false;
	selection = new SelectionModel<BlockgroupModel>(true, []);
	blockGroupResult: any[] = [];
	// Private properties
	private subscriptions: Subscription[] = [];

	isEditCondition: boolean;

	// File Element
	// Info Attachment
	@ViewChild("loginIMGFileUpload", { static: false })
	loginIMGFileInputEl: ElementRef;
	@ViewChild("dashboardIMGFileUpload", { static: false })
	dashboardIMGFileInputEl: ElementRef;
	@ViewChild("favIconIMGFileUpload", { static: false })
	favIconIMGFileInputEl: ElementRef;
	@ViewChild("templateIMGFileUpload", { static: false })
	templateIMGFileInputEl: ElementRef;

	loginIMG = {
		files: [],
		images: [],
		isEdit: false,
	};
	dashboardIMG = {
		files: [],
		images: [],
		isEdit: false,
	};
	favIconIMG = {
		files: [],
		images: [],
		isEdit: false,
	};
	templateIMG = {
		files: [],
		images: [],
		isEdit: false,
	};

	// Document Attachment
	@ViewChild("aktaPendirianIMGFileUpload", { static: false })
	aktaPendirianIMGFileInputEl: ElementRef;
	@ViewChild("suratIzinTempatUsahaIMGFileUpload", { static: false })
	suratIzinTempatUsahaIMGFileInputEl: ElementRef;
	@ViewChild("suratIzinUsahaIndustriIMGFileUpload", { static: false })
	suratIzinUsahaIndustriIMGFileInputEl: ElementRef;
	@ViewChild("domisiliPerusahaanIMGFileUpload", { static: false })
	domisiliPerusahaanIMGFileInputEl: ElementRef;
	@ViewChild("domisiliPerusahaan2IMGFileUpload", { static: false })
	domisiliPerusahaan2IMGFileInputEl: ElementRef;
	@ViewChild("addProfileIMGFileUpload", { static: false })
	addProfileIMGFileInputEl: ElementRef;

	aktaPendirianIMG = {
		files: [],
		images: [],
		isEdit: false,
	};
	suratIzinTempatUsahaIMG = {
		files: [],
		images: [],
		isEdit: false,
	};
	suratIzinUsahaIndustriIMG = {
		files: [],
		images: [],
		isEdit: false,
	};
	domisiliPerusahaanIMG = {
		files: [],
		images: [],
		isEdit: false,
	};
	domisiliPerusahaan2IMG = {
		files: [],
		images: [],
		isEdit: false,
	};

	formTarget: any;
	billStatsOption: string = "";

	ppnOptions = [
		{ value: true, name: "Ya" },
		{ value: false, name: "Tidak" },
	];

	billStatsOptions = [
		{ value: "active", name: "Aktif" },
		{ value: "inactive", name: "Tidak Aktif" },
	];

	isDecimalOptions = [
		{ value: true, name: "Aktif" },
		{ value: false, name: "Tidak Aktif" },
	];

	positions = [
		{ value: "Direktur/BM Manager", name: "Direktur/BM Manager" },
		{ value: "TR", name: "TR" },
		{ value: "Engineer", name: "Engineer" },
		{ value: "Finance", name: "Finance" },
	];

	// Organizational Structure
	addProfileIMG = {
		files: [],
		images: [],
		isEdit: false,
	};
	structure = [];

	constructor(
		private cdr: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog,
		private blockGroupFB: FormBuilder,
		private subheaderService: SubheaderService,
		private service: BlockGroupService,
		private layoutUtilsService: LayoutUtilsService,
		private store: Store<AppState>,
		private layoutConfigService: LayoutConfigService
	) {}

	isEditedName: boolean = false;

	ngOnInit() {
		this.loading$ = this.store.pipe(select(selectBlockGroupActionLoading));

		// Init DataSource
		const queryParams = new QueryParamsModel(null, "asc", "grpnm", 1, 10);
		const editSubscription = this.service
			.getListBlockGroup(queryParams)
			.subscribe((res) => {
				console.log(res, "res projek");
				this.isEditedName = res.data[0].isEditLimit;

				if (res.data[0].billstatus == 1) {
					this.billStatsOption = "active";
				} else if (res.data[0].billstatus == 0) {
					this.billStatsOption = "inactive";
				}

				this.blockgroup.clear();
				this.blockGroupResult = res.data;
				console.log(this.blockGroupResult, "res blockgroup");
				this.blockgroup = Object.assign(this.blockgroup, res.data[0]);
				this.isEditCondition = true;
				this.initBlockGroup();
			});

		this.subscriptions.push(editSubscription);

		this.initItem();
	}

	initItem() {
		this.blockgroup = new BlockgroupModel();
		this.blockgroup.clear();
		this.initBlockGroup();
	}

	initBlockGroup() {
		this.createForm();
		this.loadBlockGroupList();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}

	createForm() {
		this.blockGroupForm = this.blockGroupFB.group({
			_id: [this.blockgroup._id ? this.blockgroup._id : undefined],
			grpnm: [
				{
					value: this.blockgroup.grpnm
						? this.blockgroup.grpnm
						: undefined,
					disabled: this.isEditedName,
				},
				Validators.required,
			],
			crtdate: [
				this.blockgroup.crtdate ? this.blockgroup.crtdate : undefined,
			],
			upddate: [
				this.blockgroup.upddate ? this.blockgroup.upddate : undefined,
			],
			// prntid: [this.blockgroup.grpnm ? this.blockgroup.grpnm : undefined],
			companyName: [
				this.blockgroup.companyName
					? this.blockgroup.companyName
					: undefined,
			],
			address: [
				this.blockgroup.address ? this.blockgroup.address : undefined,
			],
			postCode: [
				this.blockgroup.postCode ? this.blockgroup.postCode : undefined,
			],
			hotline: [
				this.blockgroup.hotline ? this.blockgroup.hotline : undefined,
			],
			hotline2: [
				this.blockgroup.hotline2 ? this.blockgroup.hotline2 : undefined,
			],
			email:[this.blockgroup.email ? this.blockgroup.email :undefined,
	// 			Validators.required,
    //   Validators.pattern("[^ @]*@[^ @]*")
	],
			npwp: [this.blockgroup.npwp ? this.blockgroup.npwp : undefined],
			nib: [this.blockgroup.nib ? this.blockgroup.nib : undefined],
			color: [this.blockgroup.color ? this.blockgroup.color : "#003243"],
			mcolor: [
				this.blockgroup.mcolor ? this.blockgroup.mcolor : "#003243",
			],
			billStatus: [this.billStatsOption],
			isDecimal: [this.blockgroup.isDecimal],
			isPpn: [this.blockgroup.isPpn ? this.blockgroup.isPpn : undefined],
			note: [this.blockgroup.note ? this.blockgroup.note : undefined],
			name: [undefined],
			position: [undefined],
		});

		if (this.blockgroup._id) {
			const additionalAttach = [
				"loginIMG",
				"dashboardIMG",
				"favIconIMG",
				"templateIMG",
				"aktaPendirianIMG",
				"suratIzinTempatUsahaIMG",
				"suratIzinUsahaIndustriIMG",
				"domisiliPerusahaanIMG",
				"domisiliPerusahaan2IMG",
			];
			Object.keys(this.blockgroup).forEach((key) => {
				if (additionalAttach.includes(key)) {
					this[key].files.push(
						this.createFilefromUrl(this.blockgroup[key])
					);
					this.showPreviewAttachment(
						this.createFilefromUrl(this.blockgroup[key]),
						key,
						undefined,
						this.blockgroup[key]
					);
				}

				if (key === "structure") {
					if (this.blockgroup[key].length) {
						this.blockgroup[key].map((item) => {
							const file = this.createFilefromUrl(
								item.profileIMG
							);
							this.structure.push({
								name: item.name,
								position: item.jabatan,
								files: file,
								images: {
									name: file.name,
									url: item.profileIMG,
								},
							});
						});
					}
				}
			});

			if (this.blockgroup.countUpdate > 0) {
				this.blockGroupForm.controls.grpnm.disable();
			} else {
				this.blockGroupForm.controls.grpnm.enable();
			}
		}
	}

	goBackWithId() {
		const url = `/bgroup/list`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	refreshBlockGroup(isNew: boolean = false, id: string = "") {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/bgroup/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	reset() {
		this.blockgroup = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.blockGroupForm.markAsPristine();
		this.blockGroupForm.markAsUntouched();
		this.blockGroupForm.updateValueAndValidity();
	}

	onSubmit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.blockGroupForm.controls;
		/** check form */
		if (this.blockGroupForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}
		const _formValue = this.prepareForm();
		if (this.isEditCondition) {
			this.editBlockGroup(_formValue, this.blockgroup._id);
		} else {
			this.addBlockGroup(_formValue);
		}
	}

	loadBlockGroupList() {
		this.selection.clear();
		const queryParams = new QueryParamsModel(null, "asc", "grpnm", 1, 10);
		this.service.getListBlockGroup(queryParams).subscribe((res) => {
			this.blockGroupResult = res.data;
		});
	}

	prepareForm(): any {
		const controls = this.blockGroupForm.controls;
		const _value = new FormData();

		console.log(controls.billStatus.value, "periode billing");
		console.log(controls.isDecimal.value, "isDecimal");

		const additionalAttach = [
			"login",
			"dashboard",
			"favIcon",
			"template",
			"aktaPendirian",
			"suratIzinTempatUsaha",
			"suratIzinUsahaIndustri",
			"domisiliPerusahaan",
			"domisiliPerusahaan2",
		];
		additionalAttach.forEach((item) => {
			if (
				this[`${item}IMG`].files.length &&
				this[`${item}IMG`].isEdit === true
			) {
				this[`${item}IMG`].files.map((val) => {
					_value.append(`${item}IMG`, val);
				});
			} else `${item}IMG`;
		});

		if (this.structure.length) {
			const allStructure = [];
			const images = [];
			this.structure.map((item) => {
				allStructure.push({
					name: item.name,
					jabatan: item.position,
				});
				images.push(item.files);
				_value.append("structureIMG", item.files);
			});
			_value.append("structure", JSON.stringify(allStructure));
		}

		const template = {
			color: undefined,
			mcolor: undefined,
			note: undefined,
		};

		Object.keys(this.blockGroupForm.value).forEach((key) => {
			if (
				controls[key].value !== undefined &&
				controls[key].value !== null
			) {
				if (key === "color" || key === "mcolor" || key === "note") {
					template[key] = this.blockGroupForm.value[key];
				} else {
					_value.append(key, this.blockGroupForm.value[key]);
				}
			}
		});

		_value.append("template[color]", template.color);
		_value.append("template[mcolor]", template.mcolor);
		_value.append("template[note]", template.note);

		return _value;
	}

	addBlockGroup(_blockgroup: BlockgroupModel) {
		// this.store.dispatch(new BlockGroupOnServerCreated({ blockgroup: _blockgroup }));
		const addSubscription = this.service
			.createBlockGroup(_blockgroup)
			.subscribe(
				(res) => {
					console.log(res);

					const message = `New Project successfully created.`;
					this.layoutUtilsService.showActionNotification(
						message,
						MessageType.Create,
						5000,
						true,
						true
					);
					const favico = `${environment.baseAPI}/image/project/${res.data.favIconIMG}`;
					localStorage.setItem(
						"currentProject",
						JSON.stringify({ ...res.data, favIconIMG: favico })
					);
					this.initItem();
				},
				(err) => {
					const message =
						"Error while adding project | " + err.statusText;
					this.layoutUtilsService.showActionNotification(
						message,
						MessageType.Create,
						5000,
						true,
						false
					);
				}
			);
		this.subscriptions.push(addSubscription);
	}

	editBlockGroup(_blockgroup: BlockgroupModel, _id: string) {
		// this.store.dispatch(new BlockGroupOnServerCreated({ blockgroup: _blockgroup }));
		const editSubscription = this.service
			.updateBlockGroup(_blockgroup, _id)
			.subscribe(
				(res) => {
					const message = `Project successfully updated.`;
					this.layoutUtilsService.showActionNotification(
						message,
						MessageType.Create,
						5000,
						true,
						true
					);
					const favico = `${environment.baseAPI}/image/project/${res.data.favIconIMG}`;
					localStorage.setItem(
						"currentProject",
						JSON.stringify({ ...res.data, favIconIMG: favico })
					);
					// this.initItem();

					console.log(res);
					console.log(localStorage.getItem('currentProject'))
				},
				(err) => {
					const message =
						"Error while editing project | " + err.statusText;
					this.layoutUtilsService.showActionNotification(
						message,
						MessageType.Create,
						5000,
						true,
						false
					);
				}
			);
		this.subscriptions.push(editSubscription);
	}

	getComponentTitle() {
		let result = "Buat Proyek";

		return result;
	}

	// Attachment
	onFileSelected(e, target) {
		const files: FileList = e.target.files;
		let element: any;
		this.formTarget = this[target].files;
		element = `${target}FileInputEl`;

		// Max files validation
		if (files.length > 1 || this.formTarget.length >= 1) {
			this[element].nativeElement.value = "";
			const msg = `Only 1 file are allowed to attach.`;
			this.layoutUtilsService.showActionNotification(
				msg,
				MessageType.Read,
				5000,
				true,
				false
			);

			return;
		}

		if (files && files.length)
			for (let i = 0; i < files.length; i++) {
				// Skip upload if already exist
				const alreadyIn =
					this.formTarget.filter(
						(tFile) => tFile.name === files[i].name
					).length > 0;
				if (alreadyIn) continue;

				this.formTarget.push(files[i]);
				if (e.target.files && this.blockgroup._id)
					this[target].isEdit = true;
				this.showPreview(files[i], target);
			}
	}

	createFilefromUrl(item) {
		const splitName = item.split("/");
		const rawname = splitName[splitName.length - 1];
		const ext = rawname.split(".")[1];
		let blob = new Blob([item], {
			type: ext === "pdf" ? "application/pdf" : `image/${ext}`,
		});
		let arrayOfBlob = new Array<Blob>();
		arrayOfBlob.push(blob);
		let newBlobFile = new File(arrayOfBlob, rawname, {
			type: ext === "pdf" ? "application/pdf" : `image/${ext}`,
		});
		return newBlobFile;
	}

	showPreview(file: any, target) {
		const reader = new FileReader();
		let ext = file.name.split(".")[1];
		reader.onload = () => {
			this[target].images.push({
				name: file.name,
				url:
					ext !== "pdf"
						? reader.result
						: "https://developers.zamzar.com/assets/app/img/convert/pdf.png",
			});
			this.cdr.markForCheck();
		};
		reader.readAsDataURL(file);
	}

	showPreviewAttachment(file: any, target: any, id: any, url: string) {
		const reader = new FileReader();
		const name = url.split(".");
		const ext = name[name.length - 1];
		reader.onload = () => {
			if (id) {
				this[target][id].images.push({
					name: file.name,
					url:
						ext !== "pdf"
							? url
							: "https://developers.zamzar.com/assets/app/img/convert/pdf.png",
				});
			} else {
				this[target].images.push({
					name: file.name,
					url:
						ext !== "pdf"
							? url
							: "https://developers.zamzar.com/assets/app/img/convert/pdf.png",
				});
			}
			this.cdr.markForCheck();
		};
		reader.readAsDataURL(file);
	}

	// Delete the selected item
	deleteImg(_item, target) {
		let element: any;
		this[target].files = this[target].files.filter(
			(i) => i.name !== _item.name
		);
		this[target].images = this[target].images.filter(
			(i) => i.url !== _item.url
		);
		element = `${target}FileInputEl`;

		this[element].nativeElement.value = "";

		this.cdr.markForCheck();
	}

	numericOnly(event): boolean {
		let patt = /^([0-9])$/;
		let result = patt.test(event.key);
		return result;
	}

	addStructureItem() {
		if (
			this.blockGroupForm.get("name").value &&
			this.blockGroupForm.get("position").value &&
			this.addProfileIMG.files.length
		) {
			this.structure.push({
				name: this.blockGroupForm.get("name").value,
				position: this.blockGroupForm.get("position").value,
				images: this.addProfileIMG.images[0],
				files: this.addProfileIMG.files[0],
			});

			this.blockGroupForm.controls.name.reset();
			this.blockGroupForm.controls.position.reset();
			this.addProfileIMG.files = [];
			this.addProfileIMG.images = [];
		} else {
			const msg = `Fill out name and image field and insert an image.`;
			this.layoutUtilsService.showActionNotification(
				msg,
				MessageType.Read,
				5000,
				true,
				false
			);
		}
	}

	removeStructureItem(i: number) {
		this.structure.splice(i, 1);
	}

	changeValueItem(id: number, property: string, event: any) {
		this.structure[id][property] = event;
	}

	// Edit Structure list
	editStructureItem(_item: any, id: number) {
		const dialogRef = this.dialog.open(EditStructureListDialogComponent, {
			data: { isEdit: true, profile: _item },
			width: "600px",
		});

		dialogRef.afterClosed().subscribe((resp) => {
			if (resp) {
				this.structure[id] = resp.formValue;

				this.cdr.markForCheck();
			}
		});
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
