import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

// Material
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator, MatSort, MatSnackBar } from "@angular/material";
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, take, delay } from "rxjs/operators";
import { fromEvent, merge, Observable, of, Subscription } from "rxjs";
// LODASH
import { each, find } from "lodash";
// NGRX
import { Store, select, createFeatureSelector } from "@ngrx/store";
import { AppState } from "../../../../../core/reducers";

// Services
import { LayoutUtilsService, MessageType, QueryParamsModel } from "../../../../../core/_base/crud";
import { PowerRateDataSource } from "../../../../../core/power/rate/rate.datasource";
import { QueryPowerRateModel } from "../../../../../core/power/rate/queryrate.model";
import { PowerRateModel } from "../../../../../core/power/rate/rate.model";
import { SubheaderService } from "../../../../../core/_base/layout";
import { PowerRateService } from "../../../../../core/power/rate/rate.service";
import { PowerRateDeleted, PowerRatePageRequested } from "../../../../../core/power/rate/rate.action";
import { ModuleFeatureAccessService } from "../../../../../core/moduleRoleAccess/moduleFeatureAccess/moduleFeatureAccess.service";

@Component({
	selector: "kt-list-rate",
	templateUrl: "./list-rate.component.html",
	styleUrls: ["./list-rate.component.scss"],
})
export class ListRateComponent implements OnInit, OnDestroy {
	data = localStorage.getItem("currentUser");
	dataUser = JSON.parse(this.data);
	role = this.dataUser.role;
	dataSource: PowerRateDataSource;
	displayedColumns = ["nmrtepow", "rte", "ppju", "srvc", "actions"];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild("sort1", { static: true }) sort: MatSort;
	// Filter fields
	@ViewChild("searchInput", { static: true }) searchInput: ElementRef;
	lastQuery: QueryPowerRateModel;
	// Selection
	featureAccess = null;
	selection = new SelectionModel<PowerRateModel>(true, []);
	powerRateResult: PowerRateModel[] = [];
	// Subscriptions
	private subscriptions: Subscription[] = [];

	constructor(private activatedRoute: ActivatedRoute, private store: Store<AppState>, private router: Router, private service: PowerRateService, private layoutUtilsService: LayoutUtilsService, private subheaderService: SubheaderService, private cdr: ChangeDetectorRef, private serviceModuleFeatureAccess: ModuleFeatureAccessService) {}
	ngOnInit() {
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);
		this.loadFeatureAccess("/power-management/power/rate");
		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadPowerRateList();
				})
			)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, "keyup")
			.pipe(
				// tslint:disable-next-line:max-line-length
				debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
				distinctUntilChanged(), // This operator will eliminate duplicate values
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadPowerRateList();
				})
			)
			.subscribe();
		this.subscriptions.push(searchSubscription);

		// Set title to page breadCrumbs
		this.subheaderService.setTitle("Electricity Rate");

		// Init DataSource
		this.dataSource = new PowerRateDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(skip(1), distinctUntilChanged()).subscribe((res) => {
			this.powerRateResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First Load
		// of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
		// 	this.loadPowerRateList();
		// });
		this.loadPowerRateList();
	}
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.nmrtepow = `${searchText}`;
		return filter;
	}
	loadPowerRateList() {
		this.selection.clear();
		const queryParams = new QueryPowerRateModel(this.filterConfiguration(), this.sort.direction, this.sort.active, this.paginator.pageIndex + 1, this.paginator.pageSize);
		this.store.dispatch(new PowerRatePageRequested({ page: queryParams }));
		this.selection.clear();
	}
	deletePowerRate(_item: PowerRateModel) {
		// tslint:disable-next-line:variable-name
		const _title = "Electricity Rate Delete";
		// tslint:disable-next-line:variable-name
		const _description = "Are you sure to permanently delete this electricity rate?";
		const _waitDesciption = "Electricity rate is deleting...";
		const _deleteMessage = `Electricity rate has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			this.store.dispatch(new PowerRateDeleted({ id: _item._id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	editPowerRate(id) {
		this.router.navigate(["edit", id], { relativeTo: this.activatedRoute });
	}

	viewPowerRate(id) {
		this.router.navigate(["view", id], { relativeTo: this.activatedRoute });
	}
	ngOnDestroy() {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}
	/**
	 * loadFeatureAccess
	 * @param featureName
	 */
	loadFeatureAccess(featureName: string) {
		this.serviceModuleFeatureAccess.getRouteMenuList(featureName).subscribe((res) => {
			this.featureAccess = res.data;
		});
	}

	/**
	 * checkHiddenFeature
	 * @param type
	 * @returns
	 */
	checkHiddenFeature(type: string) {
		const roleName = this.role;

		if (this.featureAccess) {
			const findRole = this.featureAccess.role_access.find((data) => data.role == roleName);

			if (findRole) {
				if (type === "add") return !findRole.add;
				else if (type === "edit") return !findRole.edit;
				else if (type === "view") return !findRole.view;
				else if (type === "delete") return !findRole.delete;
				else return true;
			} else return true;
		} else return true;
	}
}
