import { BaseModel } from "../_base/crud";


export class TimeIndukModel extends BaseModel{
	_id : string;
	name : String;
	start_schedule: String;
	end_schedule: String;
	status:boolean;
	created_date:Date;
	


	clear(): void{
		this._id = undefined;
		this.name = "";
		this.start_schedule = "";
		this.end_schedule = "";
	}
}
