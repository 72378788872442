export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [],
		},
		aside: {
			self: {},
			items: JSON.parse(localStorage.getItem("menus")),
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
