// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// RxJS
import { Observable, Subject, of } from "rxjs";
import { catchError, finalize, takeUntil, tap } from "rxjs/operators";
// Translate
// import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
// Auth
import { AuthNoticeService, AuthService, Login } from "../../../../core/auth";

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	USERNAME: "",
	PASSWORD: "",
};

@Component({
	selector: "kt-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	showPassword = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(private router: Router, private auth: AuthService, private authNoticeService: AuthNoticeService, private store: Store<AppState>, private fb: FormBuilder, private cdr: ChangeDetectorRef, private route: ActivatedRoute) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe((params) => {
			this.returnUrl = params.returnUrl || "/";
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = `Use your account and password to continue.`;
			this.authNoticeService.setNotice(initialNotice, "info");
		}

		this.loginForm = this.fb.group({
			username: [
				DEMO_PARAMS.USERNAME,
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				]),
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}

		this.loading = true;

		const authData = {
			username: controls.username.value,
			password: controls.password.value,
		};
		// @ts-ignore
		this.auth
			.login(authData.username, authData.password)
			.pipe(
				tap((user) => {
					if (user) {
						this.store.dispatch(new Login({ authToken: user.token }));
						// this.router.navigateByUrl(this.returnUrl); // Main page
						location.href = "/";
					} else {
						this.authNoticeService.setNotice("Username / Password anda tidak bisa ditemukan", "danger");
					}
				}),
				catchError((value) => of(value)),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe((value) => {
				const statusCode = value.status;
				if (statusCode === 401) this.authNoticeService.setNotice("Can't validate your credential", "danger");
				else if (statusCode === 500) this.authNoticeService.setNotice("Max login reached, please logout the other account first!", "danger");
			});
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	togglePassword() {
		this.showPassword = !this.showPassword;
	}
}
