// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { HttpExtenstionsModel, QueryResultsModel } from '../_base/crud';

// State
import { TimeIndukState } from './time.Induk.reducer';

import { each } from 'lodash';
import { TimeIndukModel } from './time.Induk.model';


export const selectTimeIndukState = createFeatureSelector<TimeIndukState>('timeInduk');

export const selectTimeIndukById = (timeIndukId: string) => createSelector(
	selectTimeIndukState,
	timeIndukState =>  timeIndukState.entities[timeIndukId]
);

export const selectTimeIndukPageLoading = createSelector(
	selectTimeIndukState,
	timeIndukState => {
		return timeIndukState.listLoading;
	}
);

export const selectTimeIndukActionLoading = createSelector(
	selectTimeIndukState,
	timeIndukState => timeIndukState.actionsloading
);

export const selectLastCreatedTimeIndukId = createSelector(
	selectTimeIndukState,
	timeIndukState => timeIndukState.lastCreatedTimeIndukId
);

export const selectTimeIndukPageLastQuery = createSelector(
	selectTimeIndukState,
	timeIndukState => timeIndukState.lastQuery
);

export const selectTimeIndukInStore = createSelector(
	selectTimeIndukState,
	timeIndukState => {
		const items: TimeIndukModel[] = [];
		each(timeIndukState.entities, element => {
			items.push(element);
		});
		const httpExtension = new HttpExtenstionsModel();
		const result: TimeIndukModel[] = httpExtension.sortArray(items, timeIndukState.lastQuery.sortField, timeIndukState.lastQuery.sortOrder);
		return new QueryResultsModel(result, timeIndukState.totalCount, '');
	}
);

export const selectTimeIndukShowInitWaitingMessage = createSelector(
	selectTimeIndukState,
	timeIndukState => timeIndukState.showInitWaitingMessage
);

export const selectHasTimeIndukInStore = createSelector(
	selectTimeIndukState,
	queryResult => {
		if (!queryResult.totalCount) {
			return false;
		}

		return true;
	}
);
