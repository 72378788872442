// RxJS
import { of } from 'rxjs';
import { catchError, finalize, tap, debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
// import { BaseDataSource, QueryResultsModel } from '../../../_base/crud';
// State
// import { AppState } from '../../../../core/reducers';
import { selectTimeIndukInStore, selectTimeIndukPageLoading, selectTimeIndukShowInitWaitingMessage } from './time.Induk.selector';
import { BaseDataSource, QueryResultsModel } from '../_base/crud';
import {AppState} from '../../core/reducers'


export class TimeIndukDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectTimeIndukPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectTimeIndukShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectTimeIndukInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}
