// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
// import { QueryResultsModel } from '../../../_base/crud';
// Services
// import { PowerMeterIndukService } from './meter.service';
// State
// import { AppState } from '../../../../core/reducers';
import {
	TimeIndukActionTypes,
	TimeIndukPageRequested,
	TimeIndukPageLoaded,
	TimeIndukCreated,
	TimeIndukDeleted,
	TimeIndukUpdated,
	TimeIndukOnServerCreated,
	TimeIndukActionToggleLoading,
	TimeIndukPageToggleLoading
} from './time.Induk.action';
// import {QueryPowerMeterIndukModel} from './querymeter.model';
// import { QueryResultsModel } from '../../../';
import { QueryResultsModel } from '../../core/_base/crud';
import { QueryTimeIndukModel } from './queryTimeIndukModel';
import { TimeIndukService } from './timeInduk.service';
import {AppState} from '../../core/reducers'

@Injectable()
export class TimeIndukEffect {
	showPageLoadingDistpatcher = new TimeIndukPageToggleLoading({ isLoading: true });
	hidePageLoadingDistpatcher = new TimeIndukPageToggleLoading({ isLoading: false });

	showActionLoadingDistpatcher = new TimeIndukActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new TimeIndukActionToggleLoading({ isLoading: false });

	@Effect()
	loadTimeIndukPage$ = this.actions$
		.pipe(
			ofType<TimeIndukPageRequested>(TimeIndukActionTypes.TimeIndukPageRequested),
			mergeMap(( { payload } ) => {
				this.store.dispatch(this.showPageLoadingDistpatcher);
				const requestToServer = this.TimeInduk.getListTimeInduk(payload.page);
				const lastQuery = of(payload.page);
				return forkJoin(requestToServer, lastQuery);
			}),
			map(response => {
				const result: QueryResultsModel = {items: response[0].data, totalCount: response[0].totalCount, errorMessage: '', data: [] };
				const lastQuery: QueryTimeIndukModel = response[1];
				return new TimeIndukPageLoaded({
					TimeInduk: result.items,
					totalCount: result.totalCount,
					page: lastQuery
				});
			}),
		);

	@Effect()
	deleteTimeInduk$ = this.actions$
		.pipe(
			ofType<TimeIndukDeleted>(TimeIndukActionTypes.TimeIndukDeleted),
			mergeMap(( { payload } ) => {
					this.store.dispatch(this.showActionLoadingDistpatcher);
					return this.TimeInduk.deleteTimeInduk(payload.id);
				}
			),
			map(() => {
				return this.hideActionLoadingDistpatcher;
			}),
		);

	@Effect()
	updateTimeInduk = this.actions$
		.pipe(
			ofType<TimeIndukUpdated>(TimeIndukActionTypes.TimeIndukUpdated),
			mergeMap(( { payload } ) => {
				this.store.dispatch(this.showActionLoadingDistpatcher);
				return this.TimeInduk.updateTimeInduk(payload.TimeInduk);
			}),
			map(() => {
				return this.hideActionLoadingDistpatcher;
			}),
		);

	@Effect()
	createBlock$ = this.actions$
		.pipe(
			ofType<TimeIndukOnServerCreated>(TimeIndukActionTypes.TimeIndukOnServerCreated),
			mergeMap(( {payload} ) => {
				this.store.dispatch(this.showActionLoadingDistpatcher);
				return this.TimeInduk.createTimeInduk(payload.TimeInduk).pipe(
					tap(res => {
						this.store.dispatch(new TimeIndukCreated({ TimeInduk: res }));
					})
				);
			}),
			map(() => {
				return this.hideActionLoadingDistpatcher;
			}),
		);

	constructor(private actions$: Actions, private TimeInduk: TimeIndukService, private store: Store<AppState>) { }
}
