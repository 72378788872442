import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// Material
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator, MatSort, MatSnackBar } from "@angular/material";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, take, delay } from "rxjs/operators";
import { fromEvent, merge, Observable, of, Subscription } from "rxjs";
// LODASH
import { each, find } from "lodash";
// NGRX
import { Store, select, createFeatureSelector } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";

// Services
import { LayoutUtilsService, MessageType } from "../../../../core/_base/crud";
import { CustomerDataSource } from "../../../../core/customer/customer.datasource";
import { CustomerModel } from "../../../../core/customer/customer.model";
import { SubheaderService } from "../../../../core/_base/layout";
import { CustomerService } from "../../../../core/customer/customer.service";
import { CustomerDeleted, CustomerPageRequested } from "../../../../core/customer/customer.action";
import { environment } from "../../../../../environments/environment";
import { QueryCustomerModel } from "../../../../core/customer/querycustomer.model";
import { ModuleFeatureAccessService } from "../../../../core/moduleRoleAccess/moduleFeatureAccess/moduleFeatureAccess.service";

@Component({
	selector: "kt-list-customer",
	templateUrl: "./list-customer.component.html",
	styleUrls: ["./list-customer.component.scss"],
})
export class ListCustomerComponent implements OnInit, OnDestroy {
	file;
	dataSource: CustomerDataSource;
	displayedColumns = [
		// 'cstrmrcd',

		"cstrmrnm",
		// "gender",
		"phone",
		"email",
		"addrcstmr",
		"actions",
	];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild("sort1", { static: true }) sort: MatSort;
	// Filter fields
	@ViewChild("searchInput", { static: true }) searchInput: ElementRef;
	lastQuery: QueryCustomerModel;
	// Selection
	selection = new SelectionModel<CustomerModel>(true, []);
	customerResult: CustomerModel[] = [];
	data = localStorage.getItem("currentUser");
	dataUser = JSON.parse(this.data);
	role = this.dataUser.role;
	featureAccess = null;
	loading: boolean = false;
	// Subscriptions
	private subscriptions: Subscription[] = [];
	constructor(private activatedRoute: ActivatedRoute, private store: Store<AppState>, private router: Router, private service: CustomerService, private layoutUtilsService: LayoutUtilsService, private subheaderService: SubheaderService, private cdr: ChangeDetectorRef, private http: HttpClient, private modalService: NgbModal, private serviceModuleFeatureAccess: ModuleFeatureAccessService) {}
	ngOnInit() {
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);
		this.loadFeatureAccess("/customer");
		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadCustomerList();
				})
			)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, "keyup")
			.pipe(
				// tslint:disable-next-line:max-line-length
				debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
				distinctUntilChanged(), // This operator will eliminate duplicate values
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadCustomerList();
				})
			)
			.subscribe();
		this.subscriptions.push(searchSubscription);

		// Set title to page breadCrumbs
		this.subheaderService.setTitle("Customer");

		// Init DataSource
		this.dataSource = new CustomerDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(skip(1), distinctUntilChanged()).subscribe((res) => {
			this.customerResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First Load
		of(undefined)
			.pipe(take(1), delay(1000))
			.subscribe(() => {
				// Remove this line, just loading imitation
				this.loadCustomerList();
			});
		this.loadCustomerList();
	}
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;
		filter.cstrmrnm = `${searchText}`;
		return filter;
	}

	loadCustomerList() {
		this.selection.clear();
		const queryParams = new QueryCustomerModel(this.filterConfiguration(), this.sort.direction, this.sort.active, this.paginator.pageIndex + 1, this.paginator.pageSize);
		this.store.dispatch(new CustomerPageRequested({ page: queryParams }));
		this.selection.clear();
	}
	deleteCustomer(_item: CustomerModel) {
		// tslint:disable-next-line:variable-name
		const _title = "Customer Delete";
		// tslint:disable-next-line:variable-name
		const _description = "Are you sure to permanently delete this customer?";
		const _waitDesciption = "Customer is deleting...";
		const _deleteMessage = `Customer has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			this.store.dispatch(new CustomerDeleted({ id: _item._id }));
			this.ngOnInit();
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.customerResult.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.customerResult.length) {
			this.selection.clear();
		} else {
			this.customerResult.forEach((row) => this.selection.select(row));
		}
	}

	editCustomera(id) {
		this.router.navigate(["edit", id], { relativeTo: this.activatedRoute });
	}

	viewCustomera(id) {
		this.router.navigate(["view", id], { relativeTo: this.activatedRoute });
	}
	ngOnDestroy() {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}
	export() {
		this.loading = true;
		this.service.exportExcel();
		this.loading = false;
	}

	openLarge(content) {
		this.modalService.open(content, {
			size: "lg",
		});
	}

	selectFile(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			this.file = file;
		}
	}

	onSubmit() {
		const formData = new FormData();
		formData.append("file", this.file);
		this.http.post<any>(`${environment.baseAPI}/api/excel/customer/import`, formData).subscribe(
			(res) => {
				const message = `file successfully has been import.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
				this.ngOnInit();
			},
			(err) => {
				console.error(err);
				const message = "Error while importing File | " + err.statusText;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, false);
			}
		);
	}

	/**
	 * loadFeatureAccess
	 * @param featureName
	 */
	loadFeatureAccess(featureName: string) {
		this.serviceModuleFeatureAccess.getRouteMenuList(featureName).subscribe((res) => {
			this.featureAccess = res.data;
		});
	}

	/**
	 * checkHiddenFeature
	 * @param type
	 * @returns
	 */
	checkHiddenFeature(type: string) {
		const roleName = this.role;

		if (this.featureAccess) {
			const findRole = this.featureAccess.role_access.find((data) => data.role == roleName);

			if (findRole) {
				if (type === "add") return !findRole.add;
				else if (type === "edit") return !findRole.edit;
				else if (type === "view") return !findRole.view;
				else if (type === "delete") return !findRole.delete;
				else return true;
			} else return true;
		} else return true;
	}
}
