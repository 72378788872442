import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.scss']
})
export class FloorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
