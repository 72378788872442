// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

// CRUD
import { QueryTimeIndukModel } from './queryTimeIndukModel';

// Models
import { TimeIndukModel } from './time.Induk.model';


export enum TimeIndukActionTypes {
	AllUsersRequested = '[Block Module] All Block Requested',
	AllUsersLoaded = '[Block API] All Block Loaded',
	TimeIndukOnServerCreated = '[Edit TimeInduk Component] TimeInduk On Server Created',
	TimeIndukCreated = '[Edit TimeInduk Dialog] TimeInduk Created',
	TimeIndukUpdated = '[Edit TimeInduk Dialog] TimeInduk Updated',
	TimeIndukDeleted = '[TimeInduk List Page] TimeInduk Deleted',
	TimeIndukPageRequested = '[TimeInduk List Page] TimeInduk Page Requested',
	TimeIndukPageLoaded = '[TimeInduk API] TimeInduk Page Loaded',
	TimeIndukPageCancelled = '[TimeInduk API] TimeInduk Page Cancelled',
	TimeIndukPageToggleLoading = '[TimeInduk] TimeInduk Page Toggle Loading',
	TimeIndukActionToggleLoading = '[TimeInduk] TimeInduk Action Toggle Loading'
}
export class TimeIndukOnServerCreated implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukOnServerCreated;
	constructor(public payload: { TimeInduk: TimeIndukModel }) { }
}

export class TimeIndukCreated implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukCreated;
	constructor(public payload: { TimeInduk: TimeIndukModel }) { }
}


export class TimeIndukUpdated implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukUpdated;
	constructor(public payload: {
		partialTimeInduk: Update<TimeIndukModel>,
		TimeInduk: TimeIndukModel
	}) { }
}

export class TimeIndukDeleted implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukDeleted;

	constructor(public payload: { id: string }) {}
}

export class TimeIndukPageRequested implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukPageRequested;
	constructor(public payload: { page: QueryTimeIndukModel }) { }
}

export class TimeIndukPageLoaded implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukPageLoaded;
	constructor(public payload: { TimeInduk: TimeIndukModel[], totalCount: number, page: QueryTimeIndukModel  }) { }
}


export class TimeIndukPageCancelled implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukPageCancelled;
}

export class TimeIndukPageToggleLoading implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukPageToggleLoading;
	constructor(public payload: { isLoading: boolean }) { }
}

export class TimeIndukActionToggleLoading implements Action {
	readonly type = TimeIndukActionTypes.TimeIndukActionToggleLoading;
	constructor(public payload: { isLoading: boolean }) { }
}

export type TimeIndukActions = TimeIndukCreated
	| TimeIndukUpdated
	| TimeIndukDeleted
	| TimeIndukOnServerCreated
	| TimeIndukPageLoaded
	| TimeIndukPageCancelled
	| TimeIndukPageToggleLoading
	| TimeIndukPageRequested
	| TimeIndukActionToggleLoading;
