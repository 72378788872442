import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";
import { QueryResultsModel } from "../../_base/crud";

const API_BASE = `${environment.baseAPI}/api/master-feature-access`;

@Injectable({
	providedIn: "root",
})
export class ModuleFeatureAccessService {
	constructor(private http: HttpClient) {}
	// get list block group
	getMenuList(): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		return this.http.get<QueryResultsModel>(API_BASE + "/get-all", { headers: httpHeaders });
	}

	getRouteMenuList(featureName: string): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		return this.http.get<QueryResultsModel>(API_BASE + "/get-route-feature?featureName=" + featureName, { headers: httpHeaders });
	}

	private handleError<T>(operation = "operation", result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead
			// Let the app keep running by returning an empty result.
			return of(result);
		};
	}
}
