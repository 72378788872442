import { BaseModel } from "../_base/crud";
import { VillageModel } from "../state/village.model";

export class CustomerModel extends BaseModel {
	_id: string;
	// cstrmrcd : string;
	cstrmrpid: string;
	npwp: string;
	cstrmrnm: string;
	addrcstmr: string;
	phncstmr: string;
	emailcstmr: string;
	gndcstmr: string;
	idvllg: VillageModel;
	post_code: string;
	type: string;
	bankname: string;
	bankaccnt: string;
	attention: string;
	cstmrCode: string;
	rt: number
	rw: number
	kecamatan: string
	kelurahan: string
	kota:string
	blok:string
	number:string
	province: string

	clear(): void {
		this._id = undefined;
		// this.cstrmrcd = "";
		this.cstrmrpid = "";
		this.cstrmrnm = "";
		this.addrcstmr = "";
		this.phncstmr = "";
		this.emailcstmr = "";
		this.gndcstmr = "";
		this.idvllg = undefined;
		this.post_code = "";
		this.type = "";
		this.bankname = "";
		this.bankaccnt = "";
		this.rt = 0
		this.rw = 0
		this.kecamatan = ""
		this.kelurahan = ""
		this.kota = ""
		this.blok = ""
		this.number = ""
		this.attention = ""
		this.province = ""

	}
}
