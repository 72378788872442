import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { BillingOfficeModel } from "./billingOffice.model";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { QueryResultsModel } from "../_base/crud";

import * as FileSaver from "file-saver";
import { QueryBillingOfficeModel } from "./querybillingOffice.model";

const API_FLOOR_URL = `${environment.baseAPI}/api/billingOffice`;
const API_CSV = `${environment.baseAPI}/api/excel/billingOffice/export`;

@Injectable({
	providedIn: "root",
})
export class BillingOfficeService {
	constructor(private http: HttpClient) {}
	// get list block group
	getListBillingOffice(queryParams: QueryBillingOfficeModel): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		let options = {
			param: JSON.stringify(queryParams),
		};
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key]);
		}
		return this.http.get<any>(API_FLOOR_URL + "/list?" + params, { headers: httpHeaders });
	}
	getListBillingOfficeGenerated(queryParams: QueryBillingOfficeModel): Observable<QueryResultsModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		let options = {
			param: JSON.stringify(queryParams),
		};
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key]);
		}
		return this.http.get<QueryResultsModel>(API_FLOOR_URL + "/generated/list?" + params, { headers: httpHeaders });
	}

	findBillingOfficeById(_id: string): Observable<any> {
		return this.http.get<any>(`${API_FLOOR_URL}/${_id}`);
	}
	getCheckBillingOfficeActive(): Observable<any> {
		return this.http.get<any>(`${API_FLOOR_URL}/get-list-active`);
	}

	deleteBillingOffice(dataSend) {
		const formData: FormData = new FormData();
	
		if (dataSend.image) {
			formData.append('image', dataSend.image);
		}
	
		formData.append('type_void', dataSend.type_void);
		formData.append('description', dataSend.description);
	
		const url = `${API_FLOOR_URL}/delete/${dataSend._id}`;
		console.log(dataSend._id, "INI SEND ID");
	
		const req = new HttpRequest('DELETE', url, formData, {
			headers: new HttpHeaders({ 'X-HTTP-Method-Override': 'DELETE' }),
			reportProgress: true,
			responseType: 'json'
		});
	
		return this.http.request(req);
	}
	
	

	updateBillingOffice(billingOffice: BillingOfficeModel) {
		const url = `${API_FLOOR_URL}/edit/${billingOffice._id}`;
		return this.http.patch(url, billingOffice);
	}
	createBillingOffice(billingOffice: BillingOfficeModel): Observable<BillingOfficeModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		return this.http.post<BillingOfficeModel>(`${API_FLOOR_URL}/create`, billingOffice, { headers: httpHeaders });
	}

	multiInvoice(billing) {
		const url = `${API_FLOOR_URL}/template-pdf-multi`;
		return this.http.patch(url, billing);
	}

	private handleError<T>(operation = "operation", result?: any) {
		return (error: any): Observable<any> => {
			console.error(error);
			return of(result);
		};
	}

	exportSuccess(_id: string) {
		return FileSaver.saveAs(`${API_FLOOR_URL}/exportSuccess?_id=${_id}`, "export-success.xlsx");
	}
	exportFailed(_id: string) {
		return FileSaver.saveAs(`${API_FLOOR_URL}/exportFailed?_id=${_id}`, "export-failed.xlsx");
	}

	generateBillingOfficeCode(): Observable<QueryResultsModel> {
		const url = `${API_FLOOR_URL}/generate/code`;
		return this.http.get<QueryResultsModel>(url);
	}

	exportExcel() {
		return FileSaver.saveAs(`${API_CSV}`, "export-billingOffice.xlsx");
	}
}
