// Angular
import {
	Component,
	OnInit,
	Inject,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	OnDestroy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
// RxJS
import { Subscription, Observable, BehaviorSubject } from "rxjs";
// NGRX
import { select, Store } from "@ngrx/store";
// State
import { AppState } from '../../../../core/reducers';
import { ActivatedRoute, Router } from "@angular/router";
// Services and Models
import {
	selectBlockGroupActionLoading,
	selectBlockGroupById,
	selectLastCreatedBlockGroupId
} from '../../../../core/blockgroup/blockgroup.selector';
import { BlockGroupOnServerCreated, BlockGroupRequested } from '../../../../core/blockgroup/blockgroup.action';
import { BlockGroupDataSource } from '../../../../core/blockgroup/blockgroup.datasource';
import { BlockGroupService} from '../../../../core/blockgroup/blockgroup.service';
import { BlockgroupModel} from '../../../../core/blockgroup/blockgroup.model';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../core/_base/crud';

@Component({
	selector: "kt-edit-structure-list",
	templateUrl: "./edit-structure-list.dialog.component.html",
	styleUrls: ["./edit-structure-list.dialog.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class EditStructureListDialogComponent implements OnInit, OnDestroy {
	// Public properties
	dataLoading = false;

	// List Error
	isNameError$: Observable<boolean>;
	isNameErrorSubject = new BehaviorSubject<boolean>(false);

	profile: any;
	blockgroupName: any;
	blockgroupForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;

	// Private properties
	private componentSubs: Subscription;

	blockgroupList: BlockgroupModel[] = [];

	positions = [
		{value: 'Direktur/BM Manager', name: 'Direktur/BM Manager'},
		{value: 'TR', name: 'TR'},
		{value: 'Engineer', name: 'Engineer'},
		{value: 'Finance', name: 'Finance'},
	];

	// Organizational Structure
	editProfileIMG = {
		files: [],
		images: [],
		isEdit: false
	};

	// File Element
	@ViewChild("fileUpload", { static: false }) fileInputEl: ElementRef;

	/**
	 * Component constructor
	 *
	 * @param dialogRef
	 * @param data
	 * @param fb
	 * @param typesUtilsService
	 * @param initialUnitofMeasurementService
	 */
	constructor(
		public dialogRef: MatDialogRef<EditStructureListDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder,
		private store: Store<AppState>,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private blockgroupService: BlockGroupService,
		private layoutUtilsService: LayoutUtilsService,
	) {}

	ngOnInit() {
		this.profile = this.data.profile;

		this.createForm();
	}

	ngOnDestroy() {
		if (this.componentSubs) this.componentSubs.unsubscribe();
	}

	createForm() {
		this.blockgroupForm = this.fb.group({
			name: [
				{
					value: this.profile.name ? this.profile.name : undefined,
					disabled: false,
				},
				Validators.required,
			],
			position: [
				{
					value: this.profile.position ? this.profile.position : undefined,
					disabled: false,
				},
				Validators.required
			],
		});

		if(this.editProfileIMG.files) {
			this.editProfileIMG.files.push(this.profile.files);
			this.editProfileIMG.images.push(this.profile.images);
		}


	}

	/**
	 * Returns prepared academic
	 *
	 * @returns _value
	 */
	prepareForm() {
		const controls = this.blockgroupForm.controls;
		const _value = {
			name: undefined,
			position: undefined,
			files: undefined,
			images: undefined,
			isEdit: undefined
		};

		_value.name = controls.name.value;
		_value.position = controls.position.value;
		_value.isEdit = this.editProfileIMG.isEdit;
		if(this.editProfileIMG.files) {
			this.editProfileIMG.files.map(item => _value.files = item)
		}
		if(this.editProfileIMG.images) {
			this.editProfileIMG.images.map(item => _value.images = item)
		}

		return _value;
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.blockgroupForm.controls;

		/** Check form */
		if (this.blockgroupForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		// Disable actions
		this.dialogRef.disableClose = true;
		// this.viewLoading = true;

		const _formValue = this.prepareForm();
		this.addBlockgroup(_formValue);
	}

	addBlockgroup(formValue: any) {
		this.dialogRef.close({ formValue });
	}

	// Attachment
	onFileSelected(e) {
		const files: FileList = e.target.files;

		// Max files validation
		if (files.length > 1 || this.editProfileIMG.files.length >= 1) {
			this.fileInputEl.nativeElement.value = "";
			const msg = `Only 1 file are allowed to attach.`;
			this.layoutUtilsService.showActionNotification(
				msg,
				MessageType.Read,
				5000,
				true,
				false
			);

			return;
		}

		if (files && files.length)
			for (let i = 0; i < files.length; i++) {
				// Skip upload if already exist
				const alreadyIn =
					this.editProfileIMG.files.filter((tFile) => tFile.name === files[i].name)
						.length > 0;
				if (alreadyIn) continue;

				this.editProfileIMG.files.push(files[i]);
				if(e.target.files && this.profile._id) this.editProfileIMG.isEdit = true;
				this.showPreview(files[i]);
			}
	}

	showPreview(file: any) {
		const reader = new FileReader();
		let ext = file.name.split(".")[1];
		reader.onload = () => {
			this.editProfileIMG.images.push({
				name: file.name,
				url:
					ext !== "pdf"
						? reader.result
						: "https://developers.zamzar.com/assets/app/img/convert/pdf.png",
			});
			this.cdr.markForCheck();
		};
		reader.readAsDataURL(file);
	}

	// Delete the selected item
	deleteImg(_item) {
		this.editProfileIMG.files = this.editProfileIMG.files.filter((i) => i.name !== _item.name);
		this.editProfileIMG.images = this.editProfileIMG.images.filter((i) => i.url !== _item.url);

		this.fileInputEl.nativeElement.value = "";

		this.cdr.markForCheck();
	}

	handleError() {
		this.hasFormErrors = true;
	}

	goBack() {
		const url = "/finance/account-payable/add";
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/** Alert close event */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
