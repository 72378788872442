import { AfterViewInit, AfterViewChecked } from "@angular/core";
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator, MatSort, MatSnackBar } from "@angular/material";
import { debounceTime, distinctUntilChanged, tap, skip, take, delay } from "rxjs/operators";
import { fromEvent, merge, Observable, of, Subscription } from "rxjs";
import { each, find } from "lodash";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../../../../core/reducers";
import { environment } from "../../../../../../environments/environment";

import { LayoutUtilsService, MessageType, QueryParamsModel } from "../../../../../core/_base/crud";
import { User, Role, UsersDataSource, UserDeleted, UsersPageRequested, selectUserById, selectAllRoles, AuthService } from "../../../../../core/auth";
import { SubheaderService } from "../../../../../core/_base/layout";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { ModuleFeatureAccessService } from "../../../../../core/moduleRoleAccess/moduleFeatureAccess/moduleFeatureAccess.service";

// Table with EDIT item in MODAL
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
	selector: "kt-users-list",
	templateUrl: "./users-list.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements OnInit, OnDestroy {
	file;
	data = localStorage.getItem("currentUser");
	dataUser = JSON.parse(this.data);
	dataID = this.dataUser.roleId;
	dataSource: UsersDataSource;
	displayedColumns = ["username", "fullname", "_roles", "actions"];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild("sort1", { static: true }) sort: MatSort;
	@ViewChild("searchInput", { static: true }) searchInput: ElementRef;
	@ViewChild("searchInputName", { static: true }) searchInputName: ElementRef;
	lastQuery: QueryParamsModel;
	selection = new SelectionModel<User>(true, []);
	usersResult: User[] = [];
	allRoles: Role[] = [];
	private subscriptions: Subscription[] = [];
	featureAccess = null;
	role = this.dataUser.role;
	/**
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param store: Store<AppState>
	 * @param router: Router
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param subheaderService: SubheaderService
	 */
	constructor(private activatedRoute: ActivatedRoute, private store: Store<AppState>, private http: HttpClient, private router: Router, private layoutUtilsService: LayoutUtilsService, private subheaderService: SubheaderService, private service: AuthService, private modalService: NgbModal, private serviceModuleFeatureAccess: ModuleFeatureAccessService, private cdr: ChangeDetectorRef) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// load roles list
		const rolesSubscription = this.store.pipe(select(selectAllRoles)).subscribe((res) => (this.allRoles = res));
		this.subscriptions.push(rolesSubscription);
		this.loadFeatureAccess("/user-management/users");
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadUsersList();
				})
			)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, "keyup")
			.pipe(
				// tslint:disable-next-line:max-line-length
				debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
				distinctUntilChanged(), // This operator will eliminate duplicate values
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadUsersList();
				})
			)
			.subscribe();
		const searchSubscriptionName = fromEvent(this.searchInputName.nativeElement, "keyup")
			.pipe(
				// tslint:disable-next-line:max-line-length
				debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
				distinctUntilChanged(), // This operator will eliminate duplicate values
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadUsersList();
				})
			)
			.subscribe();
		this.subscriptions.push(searchSubscription);

		// Set title to page breadCrumbs
		this.subheaderService.setTitle("User Management");

		// Init DataSource
		this.dataSource = new UsersDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(skip(1), distinctUntilChanged()).subscribe((res) => {
			this.usersResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First Load
		of(undefined)
			.pipe(take(1), delay(1000))
			.subscribe(() => {
				// Remove this line, just loading imitation
				this.loadUsersList();
			});
		this.loadUsersList();
	}
	ngOnDestroy() {
		this.subscriptions.forEach((el) => el.unsubscribe());
	}
	loadUsersList() {
		this.selection.clear();
		const queryParams = new QueryParamsModel(this.filterConfiguration(), this.sort.direction, this.sort.active, this.paginator.pageIndex + 1, this.paginator.pageSize);
		this.store.dispatch(new UsersPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value.toLowerCase();
		const searchTextName: string = this.searchInputName.nativeElement.value.toLowerCase();

		filter.username = `${searchText}`;
		filter.name = `${searchTextName}`;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: User) {
		// tslint:disable-next-line:variable-name
		const _title = "User Management Delete";
		// tslint:disable-next-line:variable-name
		const _description = "Are you sure to permanently delete this user management?";
		const _waitDesciption = "User management is deleting...";
		const _deleteMessage = `User management has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}
			this.store.dispatch(new UserDeleted({ id: _item._id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	export() {
		this.service.exportExcel();
	}

	fetchUsers() {
		const messages = [];
		this.selection.selected.forEach((elem) => {
			messages.push({
				text: `${elem.first_name} ${elem.last_name}, ${elem.username}`,
				id: elem._id.toString(),
				status: elem.username,
			});
		});
		this.layoutUtilsService.fetchElements(messages);
	}

	/**
	 * Check all rows are selected
	 */
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.usersResult.length;
		return numSelected === numRows;
	}

	/**
	 * Toggle selection
	 */
	masterToggle() {
		if (this.selection.selected.length === this.usersResult.length) {
			this.selection.clear();
		} else {
			this.usersResult.forEach((row) => this.selection.select(row));
		}
	}

	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */
	getUserRolesStr(user: User): string {
		const titles: string[] = [];
		each(user.roles, (roleId: number) => {
			// tslint:disable-next-line:variable-name
			const _role = find(this.allRoles, (role: Role) => role.id === roleId);
			if (_role) {
				titles.push(_role.title);
			}
		});
		return titles.join(", ");
	}

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {
		this.router.navigate(["../users/edit", id], { relativeTo: this.activatedRoute });
	}

	viewUser(id) {
		this.router.navigate(["../users/view", id], { relativeTo: this.activatedRoute });
	}

	changePassword(id) {
		this.router.navigate(["../users/change-password", id], { relativeTo: this.activatedRoute });
	}

	openLarge(content) {
		this.modalService.open(content, {
			size: "lg",
		});
	}

	selectFile(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			this.file = file;
		}
	}

	onSubmit() {
		const formData = new FormData();
		formData.append("file", this.file);
		formData.append("created_by", this.dataID);

		this.http.post<any>(`${environment.baseAPI}/api/excel/useraccount/import`, formData).subscribe(
			(res) => {
				const message = "Importing File Success";
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 2000, true, true);
				this.file = undefined;
				if (message) {
					this.ngOnInit();
				}
			},
			(err) => {
				console.error(err);
				const message = "Error while importing File | " + err.error.message;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 2000, true, false);
			}
		);
	}

	/**
	 * loadFeatureAccess
	 * @param featureName
	 */
	loadFeatureAccess(featureName: string) {
		this.serviceModuleFeatureAccess.getRouteMenuList(featureName).subscribe((res) => {
			this.featureAccess = res.data;
		});
	}

	/**
	 * checkHiddenFeature
	 * @param type
	 * @returns
	 */
	checkHiddenFeature(type: string) {
		const roleName = this.role;

		if (this.featureAccess) {
			const findRole = this.featureAccess.role_access.find((data) => data.role == roleName);

			if (findRole) {
				if (type === "add") return !findRole.add;
				else if (type === "edit") return !findRole.edit;
				else if (type === "view") return !findRole.view;
				else if (type === "delete") return !findRole.delete;
				else return true;
			} else return true;
		} else return true;
	}
}
