import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
// import { PowerMeterIndukModel } from './meter.model';
import { catchError, map } from 'rxjs/operators';
// import { environment } from '../../../../../environments/environment';
// import { QueryResultsModel } from '../../../_base/crud';
// import { QueryPowerMeterIndukModel } from './querymeter.model';
import * as FileSaver from 'file-saver';
import { QueryTimeIndukModel } from './queryTimeIndukModel';
import { QueryResultsModel } from '../_base/crud';
import { TimeIndukModel } from './time.Induk.model';
import {environment} from '../../../environments/environment'

const API_EXCEL = `${environment.baseAPI}/api/excel/powermas/export`;
const API_POWER_RATE_URL = `${environment.baseAPI}/api/timeInduk`;

const API_POWER_RATE_URL_NEW = `${environment.baseAPI}/api/powerInduk/transaksi`;
const API_POWER_RATE_URL_MASTER = `${environment.baseAPI}/api/powerInduk/master`;



@Injectable({
	providedIn: 'root'
})
export class TimeIndukService {
	constructor(private http: HttpClient) { }
	// get list block group
	getListTimeInduk(queryParams: QueryTimeIndukModel): Observable<QueryResultsModel> {
		// return this.http.get<QueryResultsModel>(`${API_POWER_RATE_URL}`);
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		console.log('masuk service')
		// let params = new HttpParams({
		// 	fromObject: queryParams
		// });
		// @ts-ignore
		let options = {
			param: JSON.stringify(queryParams)
		}
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key])
		}
		return this.http.get<QueryResultsModel>(API_POWER_RATE_URL + '/list?' + params, { headers: httpHeaders });
	}

	/**
	 * This (old)
	 */
	// getListPowerMeterIndukforTr(queryParams): Observable<QueryResultsModel> {
	// 	// return this.http.get<QueryResultsModel>(`${API_POWER_RATE_URL}`);
	// 	const httpHeaders = new HttpHeaders();
	// 	httpHeaders.set('Content-Type', 'application/json');
	// 	// let params = new HttpParams({
	// 	// 	fromObject: queryParams
	// 	// });
	// 	// @ts-ignore
	// 	let options = {
	// 		param: JSON.stringify(queryParams)
	// 	}
	// 	let params = new URLSearchParams();
	// 	for (let key in options) {
	// 		params.set(key, options[key])
	// 	}
	// 	return this.http.get<QueryResultsModel>(API_POWER_RATE_URL + '/listforTr?' + params, { headers: httpHeaders });
	// }

	// /**
	//  * This Layouting (new)
	//  * @param queryParams 
	//  * @returns 
	//  */
	getListPowerMeterIndukforTr(queryParams): Observable<QueryResultsModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		let options = {
			params: JSON.stringify(queryParams)
		}
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key])
		}
		return this.http.get<QueryResultsModel>(API_POWER_RATE_URL_NEW + '/get/masterpower/byquery?' + params, { headers: httpHeaders });
	}


	/**
	 * This old API (old)
	 */
	// getPowerMeterInduk(id: string): Observable<any> {
	// 	return this.http.get<any>(`${API_POWER_RATE_URL}/${id}`);
	// }

	// /**
	//  * This new layouting API (new)
	//  * @param id 
	//  * @returns 
	//  */
	getPowerMeterInduk(id: string): Observable<any> {
		return this.http.get<any>(`${API_POWER_RATE_URL_NEW}/${id}`);
	}

	getPowerMeterIndukMaster(id: string): Observable<any> {
		console.log(id,"ini id")
		
		return this.http.get<any>(`${API_POWER_RATE_URL_MASTER}/${id}`);
	}



	findMeterByUnitId(_id: string): Observable<any> {
		return this.http.get<any>(`${API_POWER_RATE_URL}/meterunit/${_id}`);
	}


	deleteTimeInduk(timeIndukId: string) {
		const url = `${API_POWER_RATE_URL}/delete/${timeIndukId}`;
		return this.http.delete(url);
	}
	updateTimeInduk(timeInduk: TimeIndukModel) {
		const url = `${API_POWER_RATE_URL}/edit/${timeInduk._id}`;
		return this.http.patch(url, timeInduk);
	}
	createTimeInduk(timeInduk: TimeIndukModel): Observable<TimeIndukModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');

		return this.http.post<TimeIndukModel>(`${API_POWER_RATE_URL}/add`, timeInduk, { headers: httpHeaders });
	}

	// Get Last Consumption master Power 
	getLastConsumptionPower(queryParams) {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		let options = {
			params: JSON.stringify(queryParams)
		}
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key])
		}
		return this.http.get<any>(API_POWER_RATE_URL_NEW + '/get/lastconsumption/byquery?' + params, { headers: httpHeaders });
	}

	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead
			// Let the app keep running by returning an empty result.
			return of(result);
		};
	}

	exportExcel() {
		return FileSaver.saveAs(`${API_EXCEL}`, "export-powermeterinduk.xlsx");
	}

}
